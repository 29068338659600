import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Clown123 = () => (
  <Layout>
    <SEO title="Curs Pallasso - 1, 2, 3 Clown!" />
    <div className="bg-red-100 py-8 px-4">
      <h1 className="text-center text-5xl">1, 2, 3 Clown!</h1>
      <div className="sm:flex justify-center pt-2 text-gray-700 font-normal text-center">
        <div className="mt-2">
          🗓 Dissabtes 20, 27 de novembre i 4 de desembre
        </div>
        <div className="mt-2 pl-4">🕚 10:00h - 14:00h</div>
      </div>
      <div className="pt-6 pb-2 text-base uppercase text-red-700 text-center">
        <span className="inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-red-700 mr-2 mb-2">
          2ª edició
        </span>
        <span className="inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-red-700 mr-2 mb-2">
          Places limitades
        </span>
        <span className="inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-red-700 mr-2 mb-2">
          120€
        </span>
      </div>
    </div>

    <div className="mx-auto max-w-screen-xl px-4 sm:px-12 pt-4 pb-12">
      <div className="pt-4">
        <p>
          🤡 3 Dissabtes, 3 pallassos. En aquest curs intensiu de clown podràs
          descobrir i aprofundir l’increïble món del pallasso. Descobreix i juga
          amb la teva part més còmica amb l’ajuda de tres professionals del
          somriure.
        </p>
        <p>
          👨🏼‍🎓 3 Dissabtes, 3 Masterclass. Cada dia de curs serà guiada per un
          dels nostres pallassos professionals que de ben segur no et deixaran
          indiferent.
        </p>
      </div>
      <div className="py-12 text-center">
        <a
          href="https://forms.gle/3baou796BxoujPGJ9"
          target="_blank"
          className="uppercase font-medium border-4 border-black hover:border-red-700 hover:text-red-700 rounded py-4 px-6"
        >
          ✏️ <span className="pl-1">Inscripció al curs</span>
        </a>
      </div>

      <div className="py-4 flex flex-col sm:flex-row content-center">
        <div className="flex-0">
          <h3 className="text-3xl mb-4">El joc del clown</h3>
          <h5 className="text-xl font-medium text-red-700 pt-2 mb-1">
            🤡 Claret Papiol
          </h5>
          <div className="text-gray-700 text-base mb-4 sm:flex">
            <div className="mt-1">🗓 Dissabte, 20 de novembre</div>
            <div className="mt-1 sm:pl-4">🕚 10:00h - 14:00h</div>
          </div>

          <p>
            Fem jugar aquest personatge positiu que creu en tot el que emprèn, i
            ens atrapa amb la seva ingenuïtat.
          </p>
          <p>
            Siguem capaços de donar la volta als conflictes i convertir els
            fracassos en els més grans dels nostres èxits.
          </p>
          <p>
            Treballem el nostre sentit del ridícul i com fer-lo arribar als
            altres d’una manera sincera.
          </p>
          <p>
            Total: Deixem-nos portar per aquest antiheroi simple i somniador que
            té els peus a terra, cosa que no l’impedeix de volar amb la seva
            poesia.
          </p>
        </div>
        <img
          className="w-64 h-64 rounded-full flex-1 mt-4 ml-4"
          src="/cursos/clown-claret.png"
          alt=""
        />
      </div>

      <div className="py-6 flex flex-col flex-col-reverse sm:flex-row ">
        <img
          className="w-64 h-64 rounded-full flex-1 mt-4 mr-4"
          src="/cursos/clown-pere.png"
          alt=""
        />

        <div>
          <h3 className="text-3xl mb-4">El nostre clown de cada dia</h3>
          <h5 className="text-xl font-medium text-red-700 pt-2 mb-1">
            🤡 Pere Hosta
          </h5>
          <div className="text-gray-700 text-base mb-4 sm:flex">
            <div className="mt-1">🗓 Dissabte, 27 de novembre</div>
            <div className="mt-1 sm:pl-4">🕚 10:00h - 14:00h</div>
          </div>

          <p>
            A partir d’un mateix, a partir del que ens envolta, a partir de
            l’humor. L’observació del dia a dia ens aportarà les eines per a la
            construcció d’aquest personatge.
          </p>
          <p>
            Un estudi de camp del comportament humà i el seu entorn basat en
            l’humor.
          </p>
          <p>
            Un personatge que viu amb la sinceritat, ple d’emocions i al mateix
            temps contradiccions. El construirem a través del cos i de les seves
            possibilitats i límits, tot experimentant amb la comicitat i
            trencant estereotips.
          </p>
        </div>
      </div>

      <div className="py-6 flex flex-col sm:flex-row">
        <div>
          <h3 className="text-3xl mb-4">Silenci, neix un clown</h3>
          <h5 className="text-xl font-medium text-red-700 pt-2 mb-1">
            🤡 Ovidi Llorente
          </h5>
          <div className="text-gray-700 text-base mb-4 sm:flex">
            <div className="mt-1">🗓 Dissabte, 4 de desembre</div>
            <div className="mt-1 sm:pl-4">🕚 10:00h - 14:00h</div>
          </div>

          <p>
            El cos del clown parla per si sol; parla amb un llenguatge universal
            que va més enllà de les paraules. Una mirada, un gest, un canvi de
            ritme mostra la humanitat d'aquest ser, que contrasta amb el dia a
            dia més quotidià dels adults. Té a veure amb l'autenticitat dels
            nens i nenes, de la curiositat que els mou, de l'innocència i la
            imaginació.
          </p>
          <p>
            El silenci ens ajudarà a construïr un pont cap a aquesta dimensió
            màgica.
          </p>
        </div>
        <img
          className="w-64 h-64 rounded-full ml-4 mt-4"
          src="/cursos/clown-ovidi.png"
          alt=""
        />
      </div>

      <div className="pt-6 mb-6 text-center">
        <a
          href="https://forms.gle/3baou796BxoujPGJ9"
          target="_blank"
          className="uppercase font-medium border-4 border-black hover:border-red-700 hover:text-red-700 rounded py-4 px-6"
        >
          ✏️ <span className="pl-1">Inscripció al curs</span>
        </a>
      </div>

      <div className="mt-12">
        <Link className="underline" to="/">
          ← Tornar a portada
        </Link>
      </div>
    </div>
  </Layout>
)

export default Clown123
